/**
 * -- README --
 * This file is automatically generated, do not edit directly.
 * Edit files in `src/tokenSets` then run `npm run build-tokens` from packages/cadence to generate updates.
 */

@mixin theme {
  --rc-color-text-urgent-on-urgent-ultralight: #ff7b6a;
  --rc-color-text-urgent: #ff6463;
  --rc-color-accent-urgent: #de0902;
  --rc-color-background-urgent-ultralight: rgba(222, 9, 2, 0.3);
  --rc-color-background-urgent-medium: #9d0701;
  --rc-color-background-urgent-bold: #de0902;
  --rc-color-text-success-on-success-ultralight: #03cb39;
  --rc-color-text-success: #35b344;
  --rc-color-accent-success: #04a92e;
  --rc-color-background-success-ultralight: rgba(1, 137, 36, 0.3);
  --rc-color-background-success-medium: #01611a;
  --rc-color-background-success-bold: #018924;
  --rc-color-text-notice-on-notice-ultralight: #fbc410;
  --rc-color-accent-notice: #fbc410;
  --rc-color-background-notice-ultralight: rgba(255, 191, 0, 0.2);
  --rc-color-background-notice-medium: #b08803;
  --rc-color-background-notice-bold: #fbc410;
  --rc-color-text-neutral-on-neutral-ultralight: #b2b2b2;
  --rc-color-accent-neutral: #949494;
  --rc-color-background-neutral-ultralight: rgba(117, 117, 117, 0.3);
  --rc-color-background-neutral-medium: #4d4d4d;
  --rc-color-background-neutral-bold: #757575;
  --rc-color-text-loud-on-loud-ultralight: #ff8f49;
  --rc-color-text-loud: #fd8332;
  --rc-color-accent-loud: #ff6200;
  --rc-color-background-loud-ultralight: rgba(255, 98, 0, 0.2);
  --rc-color-background-loud-medium: #af4402;
  --rc-color-background-loud-bold: #ff6200;
  --rc-color-text-info-on-info-ultralight: #7baeff;
  --rc-color-text-info: #5a9afe;
  --rc-color-accent-info: #458cfe;
  --rc-color-background-info-ultralight: rgba(3, 106, 244, 0.3);
  --rc-color-background-info-medium: #024caf;
  --rc-color-background-info-bold: #036af4;
  --rc-color-background-recessed: #080808;
  --rc-color-background-elevated: #303030;
  --rc-color-background-on-primary: #282828;
  --rc-color-background-primary: #1c1c1c;
  --rc-color-button-danger-secondary-hovered-inverted: rgba(253, 41, 13, 0.44);
  --rc-color-button-danger-secondary-inverted: rgba(253, 45, 13, 0.24);
  --rc-color-button-danger-primary-hovered-inverted: #bf0802;
  --rc-color-button-danger-primary-inverted: #de0902;
  --rc-color-button-danger-secondary-hovered: rgba(222, 9, 2, 0.58);
  --rc-color-button-danger-secondary: rgba(222, 9, 2, 0.42);
  --rc-color-button-danger-primary-hovered: #f74435;
  --rc-color-button-danger-primary: #de0902;
  --rc-color-button-loud-secondary-hovered-inverted: rgba(255, 98, 0, 0.4);
  --rc-color-button-loud-secondary-inverted: rgba(255, 98, 0, 0.2);
  --rc-color-button-loud-primary-hovered-inverted: #d95300;
  --rc-color-button-loud-primary-inverted: #ff6200;
  --rc-color-button-loud-secondary-hovered: rgba(255, 98, 0, 0.51);
  --rc-color-button-loud-secondary: rgba(255, 98, 0, 0.35);
  --rc-color-button-loud-primary-hovered: #ff8133;
  --rc-color-button-loud-primary: #ff6200;
  --rc-color-button-main-secondary-hovered-inverted: rgba(0, 0, 0, 0.12);
  --rc-color-button-main-secondary-inverted: rgba(0, 0, 0, 0.07);
  --rc-color-button-main-primary-hovered-inverted: #121212;
  --rc-color-button-main-primary-inverted: #212121;
  --rc-color-button-main-secondary-hovered: rgba(255, 255, 255, 0.26);
  --rc-color-button-main-secondary: rgba(255, 255, 255, 0.16);
  --rc-color-button-main-primary-hovered: #ffffff;
  --rc-color-button-main-primary: #f5f5f5;
  --rc-color-text-button-danger-inverted: #d80b03;
  --rc-color-text-button-danger: #ff6463;
  --rc-color-text-button-loud-inverted: #ee5b03;
  --rc-color-text-button-loud: #fd8332;
  --rc-color-text-on-urgent-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-success-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-neutral-bold: var(--rc-color-palette-bw-white);
  --rc-color-accent-on-loud-bold: var(--rc-color-palette-bw-white);
  --rc-color-text-on-info-bold: var(--rc-color-palette-bw-white);
  --rc-color-background-overlay-selected: var(--rc-color-highlight-200);
  --rc-color-background-overlay-hovered: var(--rc-color-highlight-100);
  --rc-color-background-scrim: var(--rc-color-shadow-800);
  --rc-color-border-selectable: var(--rc-color-palette-gray-700);
  --rc-color-border-primary: var(--rc-color-palette-gray-800);
  --rc-color-text-primary-inverted: var(--rc-color-palette-gray-900);
  --rc-color-text-tertiary: var(--rc-color-palette-gray-500);
  --rc-color-text-secondary: var(--rc-color-palette-gray-400);
  --rc-color-text-primary: var(--rc-color-palette-gray-100);
  --rc-color-background-segmented-control-high-contrast: var(--rc-color-shadow-400);
  --rc-color-background-switch-track-selected: var(--rc-color-sentiment-positive-dark);
  --rc-color-background-switch-track: var(--rc-color-palette-gray-800);
  --rc-color-background-switch-handle: var(--rc-color-palette-gray-200);
  --rc-color-text-button: var(--rc-color-palette-bw-white);
  --rc-color-text-price-nudge: var(--rc-color-text-urgent);
  --rc-color-text-input-secondary: var(--rc-color-palette-gray-700);
  --rc-color-text-accent: var(--rc-color-text-loud);
  --rc-color-box-shadow-primary: var(--rc-color-shadow-900);
  --rc-color-border-input-primary: var(--rc-color-palette-gray-200);
  --rc-color-fill-box-sentiment-negative-bold: var(--rc-color-sentiment-negative-lighter);
  --rc-color-fill-box-sentiment-negative-muted: var(--rc-color-sentiment-negative-light);
  --rc-color-fill-box-sentiment-negative: var(--rc-color-sentiment-negative-dim);
  --rc-color-fill-box-kick-blue-muted: var(--rc-color-palette-kick-blue-400);
  --rc-color-fill-box-blue-muted: var(--rc-color-palette-blue-400);
  --rc-color-fill-box-teal-muted: var(--rc-color-palette-teal-200);
  --rc-color-fill-box-teal: var(--rc-color-palette-teal-600);
  --rc-color-fill-box-yellow-muted: var(--rc-color-palette-yellow-100);
  --rc-color-fill-box-yellow: var(--rc-color-palette-yellow-600);
  --rc-color-fill-box-tweed-orange-subtle: var(--rc-color-palette-tweed-orange-600);
  --rc-color-fill-box-tweed-orange: var(--rc-color-palette-tweed-orange-600);
  --rc-color-fill-box-vu-orange-muted: var(--rc-color-palette-vu-orange-400);
  --rc-color-fill-box-vu-orange-subtle: var(--rc-color-palette-vu-orange-500);
  --rc-color-fill-box-vu-orange: var(--rc-color-palette-vu-orange-500);
  --rc-color-fill-box-gray-bold: var(--rc-color-palette-gray-800);
  --rc-color-fill-box-gray-muted: var(--rc-color-palette-gray-400);
  --rc-color-fill-box-gray: var(--rc-color-palette-gray-500);
  --rc-color-border-box-sentiment-negative: var(--rc-color-sentiment-negative-dim);
  --rc-color-border-box-teal: var(--rc-color-palette-teal-600);
  --rc-color-border-box-yellow: var(--rc-color-palette-yellow-600);
  --rc-color-border-box-tweed-orange: var(--rc-color-palette-tweed-orange-600);
  --rc-color-border-box-vu-orange: var(--rc-color-palette-vu-orange-500);
  --rc-color-border-box-gray: var(--rc-color-palette-gray-400);
  --rc-color-background-box-sentiment-negative-bold: var(--rc-color-sentiment-negative-dim);
  --rc-color-background-box-sentiment-negative-muted: var(--rc-color-sentiment-negative-darker);
  --rc-color-background-box-sentiment-negative-subtle: var(--rc-color-sentiment-negative-darkest);
  --rc-color-background-box-kick-blue-bold: var(--rc-color-palette-kick-blue-500);
  --rc-color-background-box-kick-blue-muted: var(--rc-color-palette-kick-blue-600);
  --rc-color-background-box-kick-blue-subtle: var(--rc-color-palette-kick-blue-800);
  --rc-color-background-box-blue-bold: var(--rc-color-palette-blue-600);
  --rc-color-background-box-blue-muted: var(--rc-color-palette-blue-800);
  --rc-color-background-box-blue-subtle: var(--rc-color-palette-blue-900);
  --rc-color-background-box-teal-bold: var(--rc-color-palette-teal-600);
  --rc-color-background-box-teal-muted: var(--rc-color-palette-teal-800);
  --rc-color-background-box-teal-subtle: var(--rc-color-palette-teal-900);
  --rc-color-background-box-yellow-bold: var(--rc-color-palette-yellow-700);
  --rc-color-background-box-yellow-muted: var(--rc-color-palette-yellow-800);
  --rc-color-background-box-yellow-subtle: var(--rc-color-palette-yellow-900);
  --rc-color-background-box-tweed-orange-muted: var(--rc-color-palette-tweed-orange-800);
  --rc-color-background-box-tweed-orange-subtle: var(--rc-color-palette-tweed-orange-900);
  --rc-color-background-box-vu-orange-bold: var(--rc-color-palette-vu-orange-500);
  --rc-color-background-box-vu-orange-muted: var(--rc-color-palette-vu-orange-800);
  --rc-color-background-box-vu-orange-subtle: var(--rc-color-palette-vu-orange-900);
  --rc-color-background-box-gray-bold: var(--rc-color-palette-gray-500);
  --rc-color-background-box-gray-muted: var(--rc-color-palette-gray-700);
  --rc-color-background-box-gray-subtle: var(--rc-color-palette-gray-800);
  --rc-color-background-tag: var(--rc-color-highlight-200);
  --rc-color-background-tag-notification: var(--rc-color-palette-vu-orange-800);
  --rc-color-background-progressbar-indicator-muted: var(--rc-color-palette-gray-400);
  --rc-color-background-progressbar-indicator-success: var(--rc-color-sentiment-positive-dim);
  --rc-color-background-progressbar-indicator-primary: var(--rc-color-palette-gray-100);
  --rc-color-background-progressbar-track: var(--rc-color-palette-gray-800);
  --rc-color-background-page-inverted: var(--rc-color-palette-bw-white);
  --rc-color-background-module-price-guide: var(--rc-color-palette-blue-300);
  --rc-color-background-module-price-guide-secondary: var(--rc-color-palette-blue-900);
  --rc-color-background-semitransparent-inverted: var(--rc-color-highlight-500);
  --rc-color-background-semitransparent: var(--rc-color-shadow-500);
  --rc-color-background-loadingbar: var(--rc-color-palette-gray-100);
  --rc-color-background-loadingbar-inverted: var(--rc-color-palette-bw-black);
  --rc-color-background-loading-placeholder: var(--rc-color-palette-gray-800);
  --rc-color-background-input: var(--rc-color-palette-gray-900);
  --rc-color-background-input-disabled: var(--rc-color-palette-gray-800);
  --rc-color-background-input-checked: var(--rc-color-palette-bw-white);
  --rc-color-text-on-urgent-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-urgent-medium: var(--rc-color-text-primary);
  --rc-color-text-on-success-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-success-medium: var(--rc-color-text-primary);
  --rc-color-text-on-notice-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-neutral-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-neutral-medium: var(--rc-color-text-primary);
  --rc-color-text-on-loud-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-loud-medium: var(--rc-color-text-primary);
  --rc-color-text-on-info-ultralight: var(--rc-color-text-primary);
  --rc-color-text-on-info-medium: var(--rc-color-text-primary);
  --rc-color-border-selected: var(--rc-color-text-primary);
  --rc-color-text-primary-on-light: var(--rc-color-text-primary-inverted);
  --rc-color-text-primary-on-dark: var(--rc-color-text-primary);
  --rc-color-text-button-on-danger-primary-inverted: var(--rc-color-text-button);
  --rc-color-text-button-on-danger-primary: var(--rc-color-text-button);
  --rc-color-text-button-on-loud-primary-inverted: var(--rc-color-text-button);
  --rc-color-text-button-on-loud-primary: var(--rc-color-text-button);
  --rc-color-text-button-inverted: var(--rc-color-text-primary-inverted);
  --rc-color-text-mulberry: var(--rc-color-text-primary);
  --rc-color-text-textlink: var(--rc-color-text-primary);
  --rc-color-text-textlink-hovered: var(--rc-color-text-accent);
  --rc-color-text-star: var(--rc-color-text-primary);
  --rc-color-text-link-hovered: var(--rc-color-text-accent);
  --rc-color-fill-box-tweed-orange-muted: var(--rc-color-text-primary-inverted);
  --rc-color-text-on-notice-medium: var(--rc-color-text-primary-on-light);
  --rc-color-text-on-notice-bold: var(--rc-color-text-primary-on-light);
  --rc-color-text-on-loud-bold: var(--rc-color-text-primary-on-light);
}
