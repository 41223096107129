/**
 * -- README --
 * This file is automatically generated, do not edit directly.
 * 
 * Edit files in `src/tokenDefinitions/responsive` then run the following commands from packages/cadence to generate updates:
 * - npm run preformat
 * - npm run build-tokens
 */


@use '../../styles/tools/mixins/responsive';

html {
	--rc-font-size-1600: 6.4rem;
	--rc-font-size-1200: 4.8rem;
	--rc-font-size-1000: 4rem;
	--rc-font-size-900: 3.6rem;
	--rc-font-size-800: 3.2rem;
	--rc-font-size-700: 2.8rem;
	--rc-font-size-600: 2.4rem;
	--rc-font-size-550: 2.2rem;
	--rc-font-size-500: 2rem;
	--rc-font-size-450: 1.8rem;
	--rc-font-size-400: 1.6rem;
	--rc-font-size-350: 1.4rem;
	--rc-font-size-300: 1.2rem;
	--rc-space-gap-grid: var(--rc-space-6);
	--rc-space-gap-row: var(--rc-space-15);
	--rc-space-gutter-page: var(--rc-space-10);

	@include responsive.tablet {
		--rc-font-size-1600: 5.4rem;
		--rc-font-size-1200: 4.4rem;
		--rc-font-size-1000: 3.6rem;
		--rc-font-size-900: 3.2rem;
		--rc-font-size-800: 2.8rem;
		--rc-font-size-700: 2.4rem;
		--rc-font-size-600: 2.3rem;
		--rc-font-size-550: 2.1rem;
		--rc-font-size-500: 1.9rem;
		--rc-space-gap-grid: var(--rc-space-5);
		--rc-space-gap-row: var(--rc-space-12);
		--rc-space-gutter-page: var(--rc-space-6);
	}

	@include responsive.mobile {
		--rc-font-size-1600: 4.2rem;
		--rc-font-size-1200: 4rem;
		--rc-font-size-1000: 3.2rem;
		--rc-font-size-900: 2.8rem;
		--rc-font-size-800: 2.4rem;
		--rc-font-size-700: 2.3rem;
		--rc-font-size-600: 2.2rem;
		--rc-font-size-550: 2rem;
		--rc-font-size-500: 1.8rem;
		--rc-space-gap-grid: var(--rc-space-3);
		--rc-space-gap-row: var(--rc-space-10);
		--rc-space-gutter-page: var(--rc-space-4);
	}

	@include responsive.widescreen {
		--rc-space-gutter-page: var(--rc-space-20);
	}
}
